import { KeepAwake } from "@capacitor-community/keep-awake";

document.addEventListener("DOMContentLoaded", async function () {
  console.log("hello");
  const isActive = document.getElementById("isActive");
  const isNotActive = document.getElementById("isNotActive");
  isActive.classList.add("hidden");

  const [moon, sun] = document.querySelectorAll(".placeholder ~ svg");

  const button = document.getElementById("button");

  button.addEventListener("click", requestWakeLock);

  function activate() {
    isActive.classList.remove("hidden");
    isNotActive.classList.add("hidden");
    button.textContent = "deactivate";
    moon.classList.add("hidden");
    sun.classList.remove("hidden");
  }

  function deactivate() {
    isActive.classList.add("hidden");
    isNotActive.classList.remove("hidden");
    button.textContent = "activate";
    moon.classList.remove("hidden");
    sun.classList.add("hidden");
  }

  const isKeptAwake = async () => {
    const result = await KeepAwake.isKeptAwake();
    return result.isKeptAwake;
  };

  async function requestWakeLock() {
    if (await isKeptAwake()) {
      await KeepAwake.allowSleep();
      deactivate();
    } else {
      await KeepAwake.keepAwake();
      activate();
    }
  }
});
